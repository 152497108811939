/* eslint-disable no-new */
import { LazySwiperNine } from '@/utils/Controllers/LazySwiperNineController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _debounce from 'lodash/debounce';

export default () => {
  const COMPONENT_HOOK = '.js-bra-headerStripeWrapper';
  const isMobile = () => window.matchMedia('(max-width:1024px)').matches;

  const initSwiper = async item => {
    const nextEl = item.querySelector('.swiper-button-next');
    const prevEl = item.querySelector('.swiper-button-prev');
    const slides = item.querySelectorAll('.swiper-slide');

    if (slides.length < 2) {
      nextEl.classList.add('useless');
      prevEl.classList.add('useless');
    }

    const LazySwiperLib = await LazySwiperNine();

    return new LazySwiperLib(item, {
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl,
        prevEl,
      },
    });
  };

  const createCarousel = () => {
    const entries = SmarteditController.getEditorialEntries(
      `${COMPONENT_HOOK}`,
    );
    if (!entries) return;

    [...entries].forEach(async entry => {
      let slider;
      if (isMobile()) {
        slider = await initSwiper(entry);
      }
      window.addEventListener(
        'resize',
        _debounce(async () => {
          if (slider && !slider.destroyed) {
            slider.destroy(true, true);
          }
          if (isMobile()) {
            slider = await initSwiper(entry);
          }
        }, 100),
      );
    });
  };

  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
