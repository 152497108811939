import { LazySwiperNineWhenVisible } from '@/utils/Controllers/LazySwiperNineController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _isEmpty from 'lodash/isEmpty';

const SELECTOR = '.js-bra-attachment-explanation';

const loadController = async (item, SwiperLib) => {
  const controller = await import(
    /* webpackChunkName: "AttachmentExplanationController-lib" */ '@/braun/Controllers/AttachmentExplanation/AttachmentExplanationController'
  );
  controller.default(item, SwiperLib);
};

const init = () => {
  const entries = SmarteditController.getEditorialEntries(SELECTOR);
  if (!_isEmpty(entries)) {
    LazySwiperNineWhenVisible(entries, async (slider, SwiperLib) => {
      const image = slider.querySelector('img');
      if (image.complete) loadController(slider, SwiperLib);
      else {
        image.addEventListener('load', () => {
          loadController(slider, SwiperLib);
        });
      }
    });
  }
};

export default async () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
